var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "prizeItem" }, [
    (_vm.prizeType == "giftAccount" || _vm.prizeType == "giftBuy") &&
    _vm.userItem
      ? _c("div", [
          _c("img", { attrs: { src: _vm.userItem.img, alt: "" } }),
          _c("div", { staticClass: "prize-text" }, [
            _c("h3", { staticStyle: { fontWeight: "400" } }, [
              _vm._v(_vm._s(_vm.userItem.name))
            ]),
            _c("p", [
              _vm._v(
                "可用数量：" + _vm._s(_vm.userItem.stock.usableStock) + "个"
              )
            ]),
            _c(
              "p",
              [
                _c("span", [
                  _vm._v("活动冻结："),
                  _c("span", { staticClass: "lock" }, [
                    _vm._v(_vm._s(_vm.userItem.stock.lockupStock) + "个")
                  ])
                ]),
                _vm.prizeType == "giftAccount"
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.setPrize(_vm.userItem)
                          }
                        }
                      },
                      [_vm._v("设置奖品")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      : _vm.prizeType == "buyGifts" && _vm.item
      ? _c("div", [
          _c("img", { attrs: { src: _vm.item.img, alt: "" } }),
          _c("div", { staticClass: "prize-text" }, [
            _c("h3", { staticStyle: { fontWeight: "400" } }, [
              _vm._v(_vm._s(_vm.item.name))
            ]),
            _c("p", [_vm._v("市场价：￥" + _vm._s(_vm.item.price.toFixed(2)))]),
            _c(
              "p",
              [
                _c("span", [
                  _vm._v("促销价："),
                  _c("span", { staticClass: "salePrice" }, [
                    _vm._v("￥" + _vm._s(_vm.item.salePrice.toFixed(2)))
                  ])
                ]),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.item.putAwayStatus != 1
                    },
                    on: {
                      click: function($event) {
                        return _vm.toBuy()
                      }
                    }
                  },
                  [_vm._v("购买")]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }