<template>
  <el-upload
    class="avatar-uploader"
    action=""
    :show-file-list="false"
    :on-success="uploadAwardSuccess"
    :before-upload="awardBeforeUpload"
    :on-progress="getSing"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <img :src="url" alt="" v-if="showImg && url" class="uploadImg">
    <i class="el-icon-plus avatar-uploader-icon" v-else></i>
  </el-upload>
</template>

<script>
import COS from 'cos-js-sdk-v5'
import { getTmpCredential } from '@/api/myDesign'
export default {
  props: ['showImg', 'sizeLimit', 'unit'],
  data () {
    return {
      uploadFile: '',
      uploadFileName: '',
      Sign: {},
      uuid: '',
      url: '',
      format: '',
      fullscreenLoading: false,
      uploading: false
    }
  },
  created () {
    this.$bus.$on('setImgUrl', (url) => {
      this.url = url
    })
  },
  methods: {
    closeFullScreen1() {
      this.fullscreenLoading = false;
      this.uploading = false
    },
    uploadAwardSuccess (res, file) {
    },
    awardBeforeUpload (file) {
      this.fullscreenLoading = true
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2
      if (this.sizeLimit) {
        // console.log('file.size:', file.size / 1024 > 200)
        if (file.size / 1024 > 200) {
          this.$message.error(`上传图片大小不能超过${this.sizeLimit + this.unit}`)
          this.closeFullScreen1()
          return false
        }
      }
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
        this.closeFullScreen1()
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      this.uploadFile = file
      this.uploadFileName = file.name
      this.format = file.name.split('.')[file.name.split('.').length - 1]
      return (isJPG || isPNG) // && isLt2M
    },
    getSing () {
      // console.log(111)
      if (this.uploading) {
        return
      }
      // console.log(222)
      this.uploading = true
      getTmpCredential({
        token: localStorage.getItem('token')
      }).then(res => {
        if (res.code === 0) {
          this.Sign = res.data
          this.upload()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    getUuid (uuid, isWeb) {
      let idArr = []
      let hexDigits = '0123456789abcdef'
      for (let i = 0; i < 32; i++) {
        idArr[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      if (isWeb) {
        return uuid = idArr.join('')
      } else {
        return uuid = 'web_' + idArr.join('')
      }
    },
    async upload() {
      var that = this
      var cos = new COS({
        getAuthorization: function (options, callback) {
          callback({
            TmpSecretId: that.Sign.credentials.tmpSecretId,
            TmpSecretKey: that.Sign.credentials.tmpSecretKey,
            XCosSecurityToken: that.Sign.credentials.sessionToken,
            StartTime: that.Sign.startTime,
            ExpiredTime: that.Sign.expiredTime,
            expiration: that.Sign.expiration,
            requestId: that.Sign.requestId
          })
        }
      })
      const uploadFileName = this.getUuid(this.uploadFileName)
      cos.putObject({
        Bucket: process.env.VUE_APP_BUCKET,
        Region: 'ap-guangzhou',
        Key: '/upload/' + uploadFileName + '.' + this.format,
        StorageClass: 'STANDARD',
        Body: that.uploadFile, // 上传文件对象
        onProgress: function (progressData) {
        }
      }, function (err, data) {
        if (data && data.statusCode === 200) {
          const obj = {
            fileUrl: 'https://' + data.Location,
            title: that.uploadFileName.split('.')[0]
          }
          that.$emit('addImg', obj)
          that.closeFullScreen1()
          that.url = obj.fileUrl
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .avatar-uploader{
    height:100%;
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /*.avatar-uploader .el-upload:hover {*/
    /*border-color: #409eff;*/
  /*}*/
  /deep/ .avatar-uploader-icon {
    font-size: 30px;
    color: #8c939d;
    width: 160px !important;
    height: 200px !important;
    line-height: 200px;
    text-align: center;
    background: #f2f2f2;
  }
  .uploadImg {
    width: 160px;
    height: 200px;
    vertical-align: middle;
  }
  .avatar {
    width: 160px;
    height: 200px;
    display: block;
  }
</style>
