var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticClass: "avatar-uploader",
      attrs: {
        action: "",
        "show-file-list": false,
        "on-success": _vm.uploadAwardSuccess,
        "before-upload": _vm.awardBeforeUpload,
        "on-progress": _vm.getSing
      }
    },
    [
      _vm.showImg && _vm.url
        ? _c("img", {
            staticClass: "uploadImg",
            attrs: { src: _vm.url, alt: "" }
          })
        : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }