<template>
  <!-- 添加购物车弹框 -->
    <div class="cart">
      <el-dialog :visible.sync="visible" width="60%">
        <shopping-cart v-if="visible" ref="shoppingCar" buyCar="buyCar" :item="item" @closeCar="visible = false"></shopping-cart>
      </el-dialog>
    </div>
</template>

<script>
import ShoppingCart from '../views/MarketingActivityEdit/ShoppingCart.vue'

export default {
  components: {
    ShoppingCart
  },
  created () {
    this.$bus.$on('itemData', (item) => {
      this.item = item
      this.visible = true
    })
    this.$bus.$on('setVisible', () => {
      this.visible = false
      this.$emit('currentChangeHandle', 1)
      this.$emit('currentChangeHandle3', 1)
    })
  },
  beforeDestroy () {
    this.$bus.$off('itemData')
    this.$bus.$off('setVisible')
  },
  data () {
    return {
      visible: false,
      item: ''
    }
  }
}
</script>

<style>

</style>