<template>
  <!-- 购物车 -->
  <div class="cart">
    <!-- 购买 -->
    <el-table
      v-if="buyCar == 'buyCar'"
      :data="prizeData"
      tooltip-effect="dark"
      style="width: 100%">
      <el-table-column
        header-align="center"
        align="center"
        label="商品"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <img v-if="scope.row.img" :src="scope.row.img" alt="" class="goodsImg">
          <span class="goodsText">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="单价">
        <template slot-scope="scope">
          <span>{{ '￥' + scope.row.salePrice.toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="数量">
        <template slot-scope="scope">
          <el-input-number v-model="num" :min="1" :max="scope.row.stock.usableStock" label="描述文字" class="num"></el-input-number>
          <p class="inventory" v-if="num > scope.row.stock.usableStock">库存不足</p>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="小计">
        <template slot-scope="scope">
          <span style="color: red">{{ '￥' + (num * scope.row.salePrice).toFixed(2) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 购物车列表 -->
    <div class="tableList" v-else>
      <el-table
        :data="tableData"
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        :row-key="(row) => {return row.id}">
        <el-table-column
          header-align="center"
          align="center"
          type="selection"
          :reserve-selection="true"
          :selectable="isCheck"
          width="55">
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="商品"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="goodsImg">
            <span class="goodsText">{{ scope.row.productName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="单价">
          <template slot-scope="scope">
            <span>{{ '￥' + scope.row.price.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="数量">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.quantity" @change="handleChange(scope.row)" :min="1" label="描述文字" class="num" maxlength="8"></el-input-number>
            <p class="inventory" v-if="scope.row.putAwayStatus == 2">该商品已下架</p>
            <p class="inventory" v-else-if="scope.row.quantity > scope.row.inventory">库存不足</p>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="小计">
          <template slot-scope="scope">
            <span style="color: red">{{ (scope.row.quantity * scope.row.price).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          width="60">
          <template slot-scope="scope">
            <el-button type="text" style="color: #999" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      v-if="buyCar != 'buyCar'"
      class="giftPage"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, prev, pager, next, jumper">
    </el-pagination>
    <div class="settlement">
      <div class="settlement-btn" @click="buyNow">立即购买</div>
      <div class="settlement-btn addCar" v-if="buyCar == 'buyCar'" @click="add">添加至礼品购物车</div>
      <!-- <div class="settlement-text total" v-if="buyCar == 'buyCar'">
        <p>总计：<span class="prize">{{ '￥' + num * item.salePrice }}元</span></p>
      </div>
      <div class="settlement-text" v-else>
        <p>总计：<span class="prize">￥1.60元</span></p>
        <p class="preferential">优惠减￥0.10元<span class="preferential-text">优惠明细</span></p>
      </div> -->
      <div class="settlement-text total">
        <p>总计：<span class="prize">{{ buyCar == 'buyCar' ? '￥' + (num * item.salePrice).toFixed(2) : '￥' + totalPrice.toFixed(2) }}元</span></p>
      </div>
    </div>

    <!-- 订单信息 -->
    <order :multipleSelection="multipleSelection" ref="order" :buyCar="buyCar" :totalPrice="totalPrice" @getCarList="getCarList" @reset="reset"></order>
  </div>
</template>

<script>
import { addCar, getList, updateAmount, deletePrize } from '@/api/buyCar'
import order from './order'

export default {
  props: ['buyCar', 'item'],
  data () {
    return {
      tableData: [],
      prizeData: [],
      multipleSelection: [],
      num: 1,
      subtotal: '',
      pageIndex: 1,
      pageSize: 5,
      totalPage: 0,
      totalPrice: 0,
      loading: true,
      isDisabled: true
    }
  },
  created () {
    if(this.buyCar == 'buyCar' && this.item) {
      this.prizeData.push(this.item)
    }
  },
  components: {
    order
  },
  methods: {
    // 购物车列表多选
    handleSelectionChange(val) {
      this.totalPrice = 0
      this.multipleSelection = val
      // console.log('multipleSelection', this.multipleSelection)
      val.forEach((item) => {
        this.totalPrice += item.price * item.quantity
      })
    },
    // 购物车列表商品数量
     handleChange(row) {
      this.$nextTick(async () => {
        // this.isDisabled = false
        if(!row.quantity) row.quantity = 1
        this.totalPrice = 0
        if(this.multipleSelection.length) {
          this.multipleSelection.forEach((item) => {
            this.totalPrice += item.price * item.quantity
          })
        }
        const res = await updateAmount({
          quantity: row.quantity - row.quantityTemp,
          id: row.id
        })
        if(res.data && res.code == 200) {
          this.getCarList()
        }
      })
    },
    // 加入购物车
    async add () {
      if(this.num <= this.item.stock.usableStock) {
        const res = await addCar({
          quantity: this.num,
          productImg: this.item.img,
          price: this.item.salePrice,
          productName: this.item.name,
          productId: this.item.id
        })
        if(res.data && res.code == 200) {
          this.$message.success('加入购物车成功')
          this.$emit('closeCar')
        }
      }else {
        this.$message('该商品库存不足')
      }
    },
    // 获取购物车列表
    async getCarList () {
      const res = await getList({pageNo: this.pageIndex, pageSize: this.pageSize})
      if(res.code == 200) {
        this.tableData = res.data.list
        this.totalPage = res.data.total
        this.loading = false
        this.tableData.forEach(item => {
          item.quantityTemp = item.quantity
        })
        // this.isDisabled = true
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getCarList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getCarList()
    },
    // 立即购买
    buyNow () {
      if(this.buyCar == 'buyCar') {
        if(this.num <= this.item.stock.usableStock) {
          this.multipleSelection = this.prizeData
          this.multipleSelection[0].num = this.num
        }else {
          this.$message('该商品库存不足')
        }
      }
      if(this.multipleSelection.length) {
        this.$refs.order.visible = true
      }
      if(this.buyCar != 'buyCar' && !this.multipleSelection.length) {
        this.$message('未选择商品')
      }
    },
    // 删除
    del(id) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await deletePrize({'ids': [id]})
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.pageIndex = 1
              this.getCarList()
              if(this.multipleSelection.length) {
                this.multipleSelection.forEach((item, index) => {
                  if(item.id == id) {
                    this.multipleSelection.splice(index, 1)
                  }
                })
              }
            }
          })
        }
      }).catch(() => {})
    },
    // 禁用勾选
    isCheck(row) {
      return row.quantity <= row.inventory && row.putAwayStatus == 1
    },
    // 清空勾选
    reset () {
      if(this.multipleSelection.length) {
        this.$refs.multipleTable.clearSelection()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .cart {
    .tableList {
      height: 423px;
    }
    .goodsImg {
      width: 50px;
      height: 50px;
      vertical-align: middle;
    }
    .num {
      /deep/ .el-input__inner {
        width: 100%;
        background-color: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
        height: 40px;
      }
      /deep/ .el-input-number__decrease,
      /deep/ .el-input-number__increase {
        height: 38px;
      }
    }
    .goodsText {
      margin-left: 5px;
    }
    .settlement {
      height: 55px;
      background-color: #f7f7f7;
      margin-top: 30px;
      .settlement-btn {
        float: right;
        width: 140px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        color: #fff;
        background-color: #ff6600;
        cursor: pointer;
      }
      .settlement-text {
        float: right;
        margin-right: 10px;
        p {
          padding: 5px 0;
        }
        .prize {
          color: red;
          font-weight: 700;
        }
        .preferential {
          font-size: 12px;
          .preferential-text {
            background-color: #d7d7d7;
            padding: 2px;
          }
        }
      }
      .total {
        display: flex;
        height: 55px;
        align-items: center;
      }
      .addCar {
        background-color: #ff9636;
      }
    }
    .giftPage {
      text-align: right;
      height: 32px;
      line-height: 32px;
      margin-top: 20px;
      /deep/ .el-input__inner {
        background-color: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        width: 100% !important;
        height: 28px;
      }
    }
    .inventory {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      color: red;
      text-align: center;
    }
  }
</style>