<template>
  <!-- 奖品 -->
  <div class="prizeItem">
    <div v-if="(prizeType == 'giftAccount' || prizeType == 'giftBuy') && userItem">
      <img :src="userItem.img" alt="">
      <div class="prize-text">
        <h3 style="fontWeight: 400">{{ userItem.name }}</h3>
        <p>可用数量：{{ userItem.stock.usableStock }}个</p>
        <p>
          <span>活动冻结：<span class="lock">{{ userItem.stock.lockupStock }}个</span></span>
          <el-button type="primary" size="mini" class="btn" @click="setPrize(userItem)" v-if="prizeType == 'giftAccount'">设置奖品</el-button>
          <!-- <el-button type="primary" size="mini" class="btn" @click="setPrize(userItem)" v-if="prizeType == 'giftAccount'" :disabled="!!marketingActivityData.prize[0].activityId && marketingActivityData.activity.isPublish">设置奖品</el-button> -->
          <!-- <el-button type="primary" size="mini" class="btn" @click="checkDetail(userItem.goodsId)" v-else-if="prizeType == 'giftBuy'">查看明细</el-button> -->
        </p>
      </div>
    </div>
    <div v-else-if="prizeType == 'buyGifts' && item">
      <img :src="item.img" alt="">
      <div class="prize-text">
        <h3 style="fontWeight: 400">{{ item.name }}</h3>
        <p>市场价：￥{{ item.price.toFixed(2) }}</p>
        <p>
          <span>促销价：<span class="salePrice">￥{{ item.salePrice.toFixed(2) }}</span></span>
          <el-button type="danger" size="mini" @click="toBuy()" :disabled="item.putAwayStatus != 1">购买</el-button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['prizeType', 'item', 'userItem'],
  data () {
    return {

    }
  },
  methods: {
    // 设置奖品
    setPrize (data) {
      this.$emit('setPrizeInfo', data)
    },
    // 查看明细
    // checkDetail (goodsId) {
    //   const { href } = this.$router.resolve({
    //     name: "ActivityDetail",
    //     query: {
    //         goodsId
    //     }
    //   })
    //   window.open(href, '_blank')
    // },
    // 购买
    toBuy () {
      this.$bus.$emit('itemData', this.item)
    }
  },
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
}
</script>

<style lang="scss" scoped>
 .prizeItem {
   width: 350px;
   height: 100px;
   border: 1px solid #ccc;
   img {
     float: left;
     width: 98px;
     height: 98px;
   }
   .prize-text {
     float: left;
     width: 240px;
     padding: 10px 0 0 10px;
     h3 {
        font-size: 20px;
        color: #666;
        overflow: hidden;
        text-overflow:ellipsis;    
        white-space: nowrap;
      }
    p {
      margin-top: 5px;
      color: #666;
      display: flex;
      align-items: center;
      font-size: 14px;
      width: 100%;
      .salePrice {
        display: inline-block;
        width: 100px;
        color: red;
      }
      .lock {
        display: inline-block;
        width: 80px;
      }
    }
   }
   .car {
     z-index: 3000;
   }
 }
</style>