<template>
  <!-- 订单 -->
  <div>
    <el-dialog :visible.sync="visible" width="40%" append-to-body top="2%">
      <h3>订单信息</h3>
      <!-- 购买订单 -->
      <el-table
        :data="multipleSelection"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="buyCar == 'buyCar'"
        >
        <el-table-column
          header-align="center"
          align="center"
          label="商品"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <img v-if="scope.row.img" :src="scope.row.img" alt="" class="goodsImg">
            <span class="goodsText">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="单价">
          <template slot-scope="scope">
            <span>{{ '￥' + scope.row.salePrice.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="num"
          align="center"
          label="数量">
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="小计">
          <template slot-scope="scope">
            <span style="color: red">{{ '￥' + (scope.row.num * scope.row.salePrice).toFixed(2) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 购物车列表订单 -->
      <el-table
        :data="multipleSelection"
        tooltip-effect="dark"
        style="width: 100%"
        v-else
        >
        <el-table-column
          header-align="center"
          align="center"
          label="商品"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="goodsImg">
            <span class="goodsText">{{ scope.row.productName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="单价">
          <template slot-scope="scope">
            <span>{{ '￥' + scope.row.price.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="quantity"
          align="center"
          label="数量">
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="小计">
          <template slot-scope="scope">
            <span style="color: red">{{ (scope.row.quantity * scope.row.price).toFixed(2) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <h3 style="margin: 20px 0">发票信息</h3>
      <ul class="invoice">
        <li v-for="(item, index) in invoiceList" :key="index" @click="invoice(index)" :class="{'active': index == current}">{{ item }}</li>
      </ul>
      <div v-if="this.outline.ifVat">
        <p class="invoice-text">发票类型：{{ invoiceTypeList[outline.ifVat - 1] }}</p>
        <p class="invoice-text">{{ this.outline.ifVat == 3 ? '抬头名称' : '公司名称' }}：{{ this.outline.vatInvoiceTitle }}</p>
      </div>
      <div class="settlement">
        <div class="settlement-btn" @click="submitOrder">提交订单</div>
        <div class="settlement-text">
          <p>应付金额：<span class="prize" v-if="multipleSelection.length">{{ buyCar == 'buyCar' ? '￥' + (multipleSelection[0].num * multipleSelection[0].salePrice).toFixed(2) : '￥' + totalPrice.toFixed(2) }}元</span></p>
        </div>
      </div>

      <!-- 发票 -->
      <el-dialog
        title="添加发票"
        :visible.sync="showAddInvoice"
        append-to-body
        width="30%">
        <el-form label-width="120px" ref="form" :model="form" :rules="rules">
          <el-form-item label="发票类型" prop="ifVat">
            <el-radio-group v-model="form.ifVat" @change="ifVatType">
              <el-radio :label="1">增值普通发票</el-radio>
              <el-radio :label="2">增值专用发票</el-radio>
              <el-radio :label="3">个人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="抬头名称" v-if="form.ifVat == 3" prop="vatInvoiceTitle">
            <el-input v-model="form.vatInvoiceTitle" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="公司名称" v-else prop="vatInvoiceTitle">
            <el-input v-model="form.vatInvoiceTitle" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="纳税人识别号" v-if="form.ifVat != 3" prop="vatTaxpayerNum">
            <el-input v-model="form.vatTaxpayerNum"></el-input>
          </el-form-item>
            <el-form-item label="注册地址" v-if="form.ifVat == 2" prop="vatCompanyAddress">
            <el-input v-model="form.vatCompanyAddress" maxlength="100" show-word-limit></el-input>
          </el-form-item>
            <el-form-item label="注册电话" v-if="form.ifVat == 2" prop="vatTelphone">
            <el-input v-model="form.vatTelphone"></el-input>
          </el-form-item>
            <el-form-item label="开户银行" v-if="form.ifVat == 2" prop="vatBankName">
            <el-input v-model="form.vatBankName" maxlength="30" show-word-limit></el-input>
          </el-form-item>
            <el-form-item label="银行账户" v-if="form.ifVat == 2" prop="vatBankAccount">
            <el-input v-model="form.vatBankAccount"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showAddInvoice = false">取 消</el-button>
          <el-button type="primary" @click="submit">立即提交</el-button>
        </span>
      </el-dialog>

      <!-- 发票管理 -->
      <el-dialog
        title="发票信息"
        :visible.sync="isVisible"
        append-to-body
        width="30%">
        <div style="text-align: right; margin-bottom: 20px">
          <el-button size="small" @click="$router.push('/PersonalCenter/InvioceInfo')">管理发票</el-button>
        </div>
        <el-table
          :data="invoiceData"
          border
          :show-header="false"
          style="width: 100%">
          <el-table-column width="80">
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="currentRow"
                @change.native="getCurrentRow(scope.row)"
              >{{ }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <span>{{ invoiceTypeList[scope.row.ifVat - 1] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="vatInvoiceTitle">
          </el-table-column>
        </el-table>
        <!-- <el-pagination
          class="page"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination> -->
        <div style="text-align: right; margin-top: 20px">
          <el-button type="primary" @click="sub">确认</el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 支付 -->
    <el-dialog
      title="支付订单"
      :visible.sync="payVisible"
      append-to-body
      width="60%">
      <el-tabs v-model="payType" @tab-click="handleClick">
        <el-tab-pane label="微信支付" name="first">
          <div class="alipay">
            <p>应付金额：<span style="color: red">{{ '￥' + money + '元'}}</span></p>
            <p style="margin: 0 0 20px 0; color: #999" v-if="totalDifferenceAmount">{{ '因商品价格实时变动，应付金额已变为￥' + money + '元，原应付金额是'}}{{ '￥' + oldPrice + '元。' }}</p>
            <div class="code-content">
              <active-qrcode :url="payCode" codeSize="180" v-if="isCode"></active-qrcode>
            </div>
            <p>
              <img src="../../assets/img/marketing/wx.png" alt="" width="25px" style="margin-right: 5px">
              微信扫码付款
            </p>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="支付宝支付" name="second">
          <div class="alipay"> -->
            <!-- <p>应付金额：<span style="color: red">{{ '￥' + money + '元'}}</span></p>
            <active-qrcode :url="payCode" codeSize="180"></active-qrcode>
            <p>
              <img src="../../assets/img/marketing/alipay.png" alt="" width="50px">
              支付宝扫码付款
            </p> -->
            <!-- 暂不支持此方式付款！
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </el-dialog>
    <!-- 支付成功 -->
    <el-dialog
      :visible.sync="successVisible"
      append-to-body
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="paySuccess"
      width="25%">
      <div class="content">
        <img src="../../assets/img/marketing/success.png" alt="">
        <p class="success-text">支付成功!</p>
        <p class="countdown">{{ countdown }}秒后自动关闭</p>
        <div class="confirm" @click="payConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getInvoiceInfo, saveInvoiceInfo } from '@/api/userInfo'
import { sendOrder, getPayInfo, getOrderStatus } from '@/api/buyCar'
import ActiveQrcode from '@/components/ActiveQrcode.vue'

export default {
  props: ['multipleSelection', 'buyCar', 'totalPrice'],
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入注册电话'));
      } else {
        var reg1=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        var reg2=/^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})$/;
        var reg3=/^[2-9][0-9]{6,7}$/;
        if (reg1.test(value) || reg2.test(value) || reg3.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的注册电话'));
        }
      }
    }
    var validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入银行账户'));
      }else if(value.length > 30) {
        callback(new Error('请输入正确的银行账户'))
      } else {
        var reg=/^\d+$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的银行账户'));
        }
      }
    }
    var validateNum = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入纳税人识别号'));
      } else if(value.length > 30) {
        callback(new Error('请输入正确的纳税人识别号'))
      } else {
        var reg=/^[\d\w]+$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的纳税人识别号'));
        }
      }
    }
    return {
      // page: 1,
      // limit: 10,
      // totalPage: 0,
      payTime: 0, // 支付时间
      payOrderNo: '', // 支付订单号
      isCode: true,
      visible: false,
      successVisible: false,
      invoiceList: ['暂不开票', '添加发票', '发票管理'],
      current: 0,
      showAddInvoice:false,
      outline: {},
      countdown: 3,
      invoiceData: [],
      isVisible: false,
      currentRow: 0,
      payType: 'first',
      payVisible: false,
      payCode: '',
      money: '',
      timeID: '',
      oldPrice: '',
      totalDifferenceAmount: '',
      form: {
        ifVat: 1,
        vatTaxpayerNum: '',
        vatInvoiceTitle: '',
        vatCompanyAddress: '',
        vatTelphone: '',
        vatBankName: '',
        vatBankAccount: '',
      },
      invoiceTypeList: ['增值普通发票', '增值专用发票', '个人发票'],
      rules: {
        ifVat: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        vatInvoiceTitle: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        vatTaxpayerNum: [
          { required: true, validator: validateNum, trigger: 'blur' },
        ],
        vatCompanyAddress: [
          { required: true, message: '请输入注册地址', trigger: 'blur' },
        ],
        vatTelphone: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        vatBankName: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
        ],
        vatBankAccount: [
          { required: true, validator: validateAccount, trigger: 'blur' },
        ],
      }
    }
  },
  watch: {
    visible(val) {
      if(!val) {
        this.current = 0
      }
    },
    showAddInvoice(newVal) {
      if (!newVal) {
        this.$refs.form.resetFields()
      }
    },
    payVisible(val) {
      if(!val) {
        clearInterval(this.timeID)
        this.timeID = null
      }
    },
  },
  beforeDestroy () {
    clearInterval(this.timeID)
    this.timeID = null
    this.$bus.$off('setPrizeType')
  },
  components: {
    ActiveQrcode
  },
  methods: {
    invoice (index) {
      this.current = index
      if(index == 1) {
        this.showAddInvoice = true
      }else if(index == 2) {
        this.getInvoiceList()
        this.isVisible = true
      }
    },
    ifVatType () {
      this.$refs.form.clearValidate()
    },
    // 提交
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const res = await saveInvoiceInfo(this.form)
          if(res.code == 0) {
            this.$message.success('提交成功')
            this.outline = JSON.parse(JSON.stringify(this.form))
            this.showAddInvoice = false
          }else {
            this.$message(res.msg)
          }
        }
      })
    },
    // 查询发票列表
    async getInvoiceList () {
      const res = await getInvoiceInfo({limit: 20, page: 1})
      if(res.code == 0) {
        this.invoiceData = res.data.list
        // this.totalPage = res.data.totalCount
      }
    },
    // // 每页数
    // sizeChangeHandle (val) {
    //   this.limit = val
    //   this.page = 1
    //   this.getInvoiceList()
    // },
    // // 当前页
    // currentChangeHandle (val) {
    //   this.page = val
    //   this.getInvoiceList()
    // },
    // 选择发票
    getCurrentRow (row) {
      this.row = row
    },
    // 确认
    sub () {
      if(!this.invoiceData.length) {
        this.$message('请先添加发票')
      }else {
        if(this.row) {
          this.outline = this.row
        }else {
          this.outline = this.invoiceData[0]
        }
      }
      this.isVisible = false
    },
    // 提交订单
    async submitOrder () {
      let itemList = JSON.parse(JSON.stringify(this.multipleSelection))
      if(this.buyCar == 'buyCar') {
        // console.log('itemList', itemList)
        itemList.forEach((item) => {
          item.productImg = item.img
          item.price = item.salePrice
          item.productId = item.id
          item.productName = item.name
          item.quantity = item.num
        })
      }else {
        itemList.forEach((item) => {
          item.image = item.productImg
          item.purchaseCount = item.quantity
          item.cartId = item.id
        })
      }
      const res = await sendOrder({
        invoiceStatus: this.current != 0 && this.outline.ifVat ? 1 : 0,
        invoiceTitle: this.current != 0 && this.outline.ifVat ? JSON.stringify(this.outline) : '',
        itemList,
      })
      if(res.code == 200) {
        if(this.buyCar != 'buyCar') {
          this.$parent.reset()
          this.$parent.pageIndex = 1
          this.$parent.getCarList()
        }
        this.visible = false
        if(!!res.data.totalDifferenceAmount) {
          this.totalDifferenceAmount = res.data.totalDifferenceAmount
          this.oldPrice = res.data.oldTotalAmount
        }
        // const result = await getPayInfo({orderNo: res.data.orderNo, payType: 1})
        // if(result.code == 200) {
        //   this.payCode = result.data.codeUrl
        //   this.money = result.data.payAmount
        //   this.payType = 'first'
        //   this.payVisible = true
        //   this.getPayStatus(res.data.orderNo)
        // }
        this.payInfo(res.data.orderNo)
      }else {
        this.$message.error(res.msg)
        this.visible = false
        if(this.buyCar == 'buyCar') {
          this.$bus.$emit('setVisible')
        }else {
          this.$emit('getCarList')
          this.$emit('reset')
        }
      }
    },
    // 获取支付信息
    async payInfo (orderNo) {
      this.payTime = new Date().getTime()
      this.payOrderNo = orderNo
      const result = await getPayInfo({orderNo, payType: 1})
      if(result.code == 200) {
        this.isCode = false
        this.payCode = result.data.codeUrl
        setTimeout(() => {
          this.isCode = true
        }, 0);
        this.money = result.data.payAmount
        this.payType = 'first'
        this.payVisible = true
        this.getPayStatus(orderNo)
      }
    },
    handleClick(tab, event) {
      // console.log(tab)
    },
    // 查看订单支付状态
    getPayStatus (orderNo) {
      this.timeID = setInterval(async () => {
        if(new Date().getTime() - this.payTime > 1800000) {
          clearInterval(this.timeID)
          this.timeID = null
          this.payInfo(this.payOrderNo)
        }else {
          const res = await getOrderStatus({orderNo})
          if(res.code == 200 && res.data == 1) {
            this.successVisible = true
            this.refresh()
          }else if(res.code == 200 && res.data == 2) {
            this.$message('订单已取消')
            this.payVisible = false
            this.$bus.$emit('setVisible')
          }else if(res.code == 200 && res.data == 3) {
            this.$message('订单已失效')
            this.payVisible = false
            this.$bus.$emit('setVisible')
          }
        }
      }, 3000)
    },
    // 支付成功
    refresh () {
      this.payVisible = false
      let id = setInterval(() => {
        this.countdown--
        if(this.countdown === 0) {
          clearInterval(id)
          this.successVisible = false
        }
      }, 1000);
      setTimeout(() => {
        this.$bus.$emit('setVisible')
      }, 3000);
      this.$bus.$emit('setPrizeType', 'first')
    },
    // 确定
    payConfirm () {
      this.payVisible = false
      this.$bus.$emit('setVisible')
      this.$bus.$emit('setPrizeType', 'first')
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsImg {
  width: 50px;
  height: 50px;
  vertical-align: middle;
}
.invoice {
  display: flex;
  li {
    width: 90px;
    height: 32px;
    padding: 4px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;
    cursor: pointer;
    &.active {
      background-color: #1890ff;
      color: #fff;
    }
  }
}
.settlement {
  height: 55px;
  background-color: #f7f7f7;
  margin-top: 50px;
  .settlement-btn {
    float: right;
    width: 140px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    color: #fff;
    background-color: #ff3300;
    cursor: pointer;
  }
  .settlement-text {
    float: right;
    margin-right: 10px;
    height: 55px;
    display: flex;
    align-items: center;
    p {
      padding: 5px 0;
    }
    .prize {
      color: red;
      font-weight: 700;
    }
  }
}
.invoice-text {
  margin: 10px;
}
.page {
  margin-top: 20px;
  text-align: right;
}
.alipay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 340px;
  p {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 380px;
  justify-content: space-between;
  .success-text {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
  .confirm {
    width: 250px;
    height: 50px;
    background: #2468F2;
    border-radius: 4px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #F5F5F5;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }
  .countdown {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
}
.paySuccess {
  /deep/ .el-dialog {
    border-radius: 16px;
  }
}
.code-content {
  width: 180px;
  height: 180px;
}
</style>